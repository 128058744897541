export const Page = {
  paddingTop: '50px',
  marginBottom: '40px',
}

export const Form = {
  paddingTop: '20px',
  paddingLeft: '20px',
  clear: 'both',
}

export const SubHeader = {
  color: 'black',
  font: '400 16px Roboto,sans-serif',
}

export const Input = {
  width: '225px',
}

export const SaveButton = {
  marginTop: '20px',
}

export const Select = {
  width: '165px',
}
