import React, { Component } from 'react'
import Button from '../../../../common/Button'
import { Select, Input } from '../../../../common/FormElement'
import { validateEmail } from '../../../../common/Validators'
import * as styles from '../assets/styles'

const _isValidProfile = errorsDict => {
  for (const key in errorsDict) {
    if (errorsDict[key]) {
      return false
    }
  }
  return true
}

const _save = (actions, appState, venueProfile, e) => {
  if (_isValidProfile(appState.errors)) {
    actions.tryPostVenueProfile(venueProfile)
  }
}

const _changeField = (actions, fieldName, e) => {
  actions.updateField(fieldName, e.target.value)
}

const _changeUrlField = (actions, fieldName, e) => {
  const errMsg = 'Please enter a valid url'
  actions.updateField(fieldName, e.target.value, !$.isUrlValid(e.target.value) && e.target.value ? errMsg : undefined)
}

const _changeEmailField = (actions, fieldName, e) => {
  const errMsg = 'Invalid email address'
  actions.updateField(fieldName, e.target.value, !validateEmail(e.target.value) && e.target.value ? errMsg : undefined)
}

const _changeMaximumDeliveryDiscountField = (actions, fieldName, e) => {
  const errMsg = 'Invalid discount'
  actions.updateField(fieldName, e.target.value, !_validateMaximumDeliveryDiscount(e.target.value) && e.target.value ? errMsg : undefined)
}

const _validateMaximumDeliveryDiscount = value => value >= 0 && value <= 100

const generateUserOptions = venueUsers => ['Custom'].concat(Object.keys(venueUsers))

export const VenueInformationForm = ({ venueProfile, appState, actions }) => (
  <form style={styles.Form} className="styled" data-sr-validate>
    <div style={styles.SubHeader}>Venue Information</div>
    <div className="inline">
      <Input
        name="address"
        labelText="Address"
        inputCss={styles.Input}
        value={venueProfile.address}
        onChangeHandler={_changeField.bind(this, actions, 'address')}
        disabled={appState.isGetting || appState.isPosting}
      />
      <Input
        name="cross_street"
        labelText="Cross Street"
        inputCss={styles.Input}
        value={venueProfile.crossStreet}
        onChangeHandler={_changeField.bind(this, actions, 'crossStreet')}
        disabled={appState.isGetting || appState.isPosting}
      />
      <Input
        name="city"
        labelText="City"
        inputCss={styles.Input}
        value={venueProfile.city}
        onChangeHandler={_changeField.bind(this, actions, 'city')}
        disabled={appState.isGetting || appState.isPosting}
      />
    </div>
    <div className="inline">
      <Input
        name="state"
        labelText="State / Province"
        inputCss={styles.Input}
        value={venueProfile.state}
        onChangeHandler={_changeField.bind(this, actions, 'state')}
        disabled={appState.isGetting || appState.isPosting}
      />
      <Input
        name="postal_code"
        labelText="Postal Code"
        inputCss={styles.Input}
        value={venueProfile.postalCode}
        onChangeHandler={_changeField.bind(this, actions, 'postalCode')}
        disabled={appState.isGetting || appState.isPosting}
      />
      <Input
        name="country"
        labelText="Country"
        inputCss={styles.Input}
        value={venueProfile.country}
        onChangeHandler={_changeField.bind(this, actions, 'country')}
        disabled={appState.isGetting || appState.isPosting}
      />
    </div>
    <div className="inline">
      <Input
        name="phone_number"
        labelText="Phone"
        inputCss={styles.Input}
        value={venueProfile.phoneNumber}
        onChangeHandler={_changeField.bind(this, actions, 'phoneNumber')}
        disabled={appState.isGetting || appState.isPosting}
      />
      <Input
        name="contact_email"
        labelText="Primary Contact Email"
        type="email"
        inputCss={styles.Input}
        value={venueProfile.contactEmail}
        onChangeHandler={_changeEmailField.bind(this, actions, 'contactEmail')}
        disabled={appState.isGetting || appState.isPosting}
        error={appState.errors.contactEmail}
      />
      <Input
        name="website"
        labelText="Website"
        inputCss={styles.Input}
        placeholder="http://"
        value={venueProfile.website}
        onChangeHandler={_changeUrlField.bind(this, actions, 'website')}
        disabled={appState.isGetting || appState.isPosting}
        error={appState.errors.website}
      />
    </div>
    <div className="inline">
      <Input
        name="menu_link"
        labelText="Menu Link"
        inputCss={styles.Input}
        placeholder="http://"
        value={venueProfile.menuLink}
        onChangeHandler={_changeUrlField.bind(this, actions, 'menuLink')}
        disabled={appState.isGetting || appState.isPosting}
        error={appState.errors.menuLink}
      />
      <Input
        name="gmaps_link"
        labelText="Google Maps Link"
        inputCss={styles.Input}
        placeholder="http://"
        value={venueProfile.gmapsLink}
        onChangeHandler={_changeUrlField.bind(this, actions, 'gmapsLink')}
        disabled={appState.isGetting || appState.isPosting}
        error={appState.errors.gmapsLink}
      />
      <Input
        name="reservation_widget_link"
        labelText="Reservation Widget Link"
        inputCss={styles.Input}
        placeholder="http://"
        value={venueProfile.reservationWidgetLink}
        onChangeHandler={_changeUrlField.bind(this, actions, 'reservationWidgetLink')}
        disabled={appState.isGetting || appState.isPosting}
        error={appState.errors.reservationWidgetLink}
      />
    </div>
    <div className="inline">
      <Button style={styles.SaveButton} label="Save" onClick={_save.bind(this, actions, appState, venueProfile)} />
    </div>
    <div className="float-end" />
  </form>
)

export const SocialMediaForm = ({ venueProfile, appState, actions }) => (
  <form style={styles.Form} className="styled" data-sr-validate>
    <div style={styles.SubHeader}>Social Media Links</div>
    <div className="inline">
      <Input
        name="facebook_link"
        labelText="Facebook"
        inputCss={styles.Input}
        placeholder="http://"
        value={venueProfile.facebookLink}
        onChangeHandler={_changeUrlField.bind(this, actions, 'facebookLink')}
        disabled={appState.isGetting || appState.isPosting}
        error={appState.errors.facebookLink}
      />
      <Input
        name="instagram_link"
        labelText="Instagram"
        inputCss={styles.Input}
        placeholder="http://"
        value={venueProfile.instagramLink}
        onChangeHandler={_changeUrlField.bind(this, actions, 'instagramLink')}
        disabled={appState.isGetting || appState.isPosting}
        error={appState.errors.instagramLink}
      />
      <Input
        name="snapchat_link"
        labelText="Snapchat"
        inputCss={styles.Input}
        placeholder="http://"
        value={venueProfile.snapchatLink}
        onChangeHandler={_changeUrlField.bind(this, actions, 'snapchatLink')}
        disabled={appState.isGetting || appState.isPosting}
        error={appState.errors.snapchatLink}
      />
    </div>
    <div className="inline">
      <Input
        name="twitter_link"
        labelText="Twitter"
        inputCss={styles.Input}
        placeholder="http://"
        value={venueProfile.twitterLink}
        onChangeHandler={_changeUrlField.bind(this, actions, 'twitterLink')}
        disabled={appState.isGetting || appState.isPosting}
        error={appState.errors.twitterLink}
      />
    </div>
    <div className="inline">
      <Button style={styles.SaveButton} label="Save" onClick={_save.bind(this, actions, appState, venueProfile)} />
    </div>
    <div className="float-end" />
  </form>
)

export const MarketingInformationForm = ({ MEDIA_URL, venueProfile, appState, actions }) => (
  <form style={styles.Form} className="styled" data-sr-validate>
    <div style={styles.SubHeader}>Marketing Information</div>
    {venueProfile.customDomain && (
      <>
        <div className="inline">
          <div
            style={{
              paddingTop: '15px',
              font: '500 13px Roboto,sans-serif',
            }}
          >
            Custom Domain Prefix
          </div>
          <Input
            name="custom_domain_prefix"
            inputCss={styles.Input}
            value={venueProfile.customDomainPrefix}
            onChangeHandler={_changeField.bind(this, actions, 'customDomainPrefix')}
            disabled={appState.isGetting || appState.isPosting}
          />
          <div
            style={{
              paddingTop: '7.5px',
              font: '400 13px Roboto,sans-serif',
              display: 'inline-block',
            }}
          >
            @{venueProfile.customDomain}
          </div>
        </div>
      </>
    )}
    <div className="inline">
      <div
        style={{
          paddingTop: '15px',
          font: '500 13px Roboto,sans-serif',
        }}
      >
        Send From User:
      </div>
      <Select
        name="marketing_sender_user"
        matchValue
        inputCss={styles.Select}
        options={generateUserOptions(venueProfile.users)}
        MEDIA_URL={MEDIA_URL}
        onChangeHandler={_changeField.bind(this, actions, 'currentSender')}
        defaultValue={venueProfile.selectedCurrentSender}
      />
    </div>
    <br />
    <div className="inline">
      <div
        style={{
          paddingTop: '15px',
          font: '500 13px Roboto,sans-serif',
        }}
      >
        Send Email As:
      </div>
      <Input
        name="marketing_sender_name"
        labelText="Sender Name"
        inputCss={styles.Input}
        value={venueProfile.currentSender.marketingSenderName}
        onChangeHandler={_changeField.bind(this, actions, 'marketingSenderName')}
        disabled={appState.isGetting || appState.isPosting}
      />
      <Input
        name="marketing_sender_role"
        labelText="Sender Role"
        inputCss={styles.Input}
        value={venueProfile.currentSender.marketingSenderRole}
        onChangeHandler={_changeField.bind(this, actions, 'marketingSenderRole')}
        disabled={appState.isGetting || appState.isPosting}
      />
      <Input
        name="marketing_email"
        labelText="Email Address"
        type="email"
        inputCss={styles.Input}
        value={venueProfile.currentSender.marketingEmail}
        onChangeHandler={_changeEmailField.bind(this, actions, 'marketingEmail')}
        disabled={appState.isGetting || appState.isPosting}
        error={appState.errors.marketingEmail}
      />
    </div>
    <br />
    <div className="inline">
      <Input
        name="maximum_delivery_discount"
        type="number"
        max="100"
        min="0"
        labelText="Max Delivery Discount (%)"
        inputCss={styles.Input}
        value={venueProfile.maximumDeliveryDiscount}
        onChangeHandler={_changeMaximumDeliveryDiscountField.bind(this, actions, 'maximumDeliveryDiscount')}
        disabled={appState.isGetting || appState.isPosting}
        error={appState.errors.maximumDeliveryDiscount}
      />
    </div>
    <div className="inline">
      <Button style={styles.SaveButton} label="Save" onClick={_save.bind(this, actions, appState, venueProfile)} />
    </div>
    <div className="float-end" />
  </form>
)
