import { srGet, srPost } from '@sevenrooms/core/api'
import {
  GETTING_VENUE_PROFILE,
  GET_SUCCESS_VENUE_PROFILE,
  GET_FAIL_VENUE_PROFILE,
  POSTING_VENUE_PROFILE,
  POST_SUCCESS_VENUE_PROFILE,
  POST_FAIL_VENUE_PROFILE,
  UPDATE_FIELD,
} from './ActionTypes'

// form and app actions
const getReservationWidgetLinkText = venueUrlKey => `https://${window.location.hostname}/reservations/${venueUrlKey}`

export const updateField = (fieldName, value, error) => ({ type: UPDATE_FIELD, fieldName, value, error })

// Actions to retrieve the venue profile

export const gettingVenueProfile = () => ({ type: GETTING_VENUE_PROFILE })

export const getSuccessVenueProfile = venueProfile => ({ type: GET_SUCCESS_VENUE_PROFILE, venueProfile })

export const getFailVenueProfile = () => ({ type: GET_FAIL_VENUE_PROFILE })

export const fetchVenueProfile = (venueId, errHandler) =>
  srGet(`/api-yoa/venue_profile`, { venue: venueId }).then(response => {
    if (!response.ok && errHandler) {
      errHandler(response)
    }
    return response.data.venue
  })

export const fetchVenueUsers = (venueId, errHandler) =>
  srGet(`/api-yoa/venue_users`, { venue: venueId }).then(response => {
    if (!response.ok && errHandler) {
      errHandler(response)
    }
    return { venueUsers: response.data }
  })

export const tryGetVenueProfile = venueId => dispatch => {
  dispatch(gettingVenueProfile())

  const profileErrorHandler = () => dispatch(getFailVenueProfile())

  Promise.all([fetchVenueProfile(venueId, profileErrorHandler), fetchVenueUsers(venueId, profileErrorHandler)]).then(results => {
    const venueProfileResults = results[0]
    const venueUserResults = results[1]

    const profileObject = {
      id: venueProfileResults.id || '',
      name: venueProfileResults.name || '',
      address: venueProfileResults.address || '',
      crossStreet: venueProfileResults.cross_street || '',
      city: venueProfileResults.city || '',
      state: venueProfileResults.state || '',
      country: venueProfileResults.country || '',
      postalCode: venueProfileResults.postal_code || '',
      gmapsLink: venueProfileResults.gmaps_link || '',
      phoneNumber: venueProfileResults.phone_number || '',
      website: venueProfileResults.website || '',
      menuLink: venueProfileResults.menu_link || '',
      facebookLink: venueProfileResults.facebook_link || '',
      instagramLink: venueProfileResults.instagram_link || '',
      snapchatLink: venueProfileResults.snapchat_link || '',
      twitterLink: venueProfileResults.twitter_link || '',
      contactEmail: venueProfileResults.contact_email || '',
      reservationWidgetLink: venueProfileResults.reservation_widget_link || getReservationWidgetLinkText(venueProfileResults.url_key),
      marketingSenderName: venueProfileResults.marketing_sender_name || '',
      marketingEmail: venueProfileResults.marketing_email || '',
      marketingSenderRole: venueProfileResults.marketing_sender_role || '',
      maximumDeliveryDiscount: venueProfileResults.maximum_delivery_discount || 0,
      urlKey: venueProfileResults.url_key || '',
      currentSender: {
        marketingSenderName: venueProfileResults.marketing_sender_name || '',
        marketingEmail: venueProfileResults.marketing_email || '',
        marketingSenderRole: venueProfileResults.marketing_sender_role || '',
      },
      users: {},
      selectedCurrentSender: '',
      customDomainPrefix: venueProfileResults.custom_domain_prefix || '',
      customDomain: venueProfileResults.custom_domain || '',
    }
    venueUserResults.venueUsers.forEach(user => {
      profileObject.users[user.fullname] = user
    })

    if (Object.keys(profileObject.users).includes(profileObject.currentSender.marketingSenderName)) {
      profileObject.selectedCurrentSender = profileObject.currentSender.marketingSenderName
    }

    return dispatch(getSuccessVenueProfile(profileObject))
  })
}

// Actions to save the profile

export const postingVenueProfile = () => ({ type: POSTING_VENUE_PROFILE })

export const postSuccessVenueProfile = () => ({ type: POST_SUCCESS_VENUE_PROFILE })

export const postFailVenueProfile = () => ({ type: POST_FAIL_VENUE_PROFILE })

export const saveVenueProfile = (venueProfile, errHandler) =>
  srPost(`/api-yoa/venue_profile`, venueProfile).then(response => {
    if (!response.ok && errHandler) {
      errHandler(response)
    }
    return {}
  })

export const tryPostVenueProfile = profile => dispatch => {
  dispatch(postingVenueProfile())
  const errHandler = () => dispatch(postFailVenueProfile())

  saveVenueProfile(
    {
      venue: profile.id,
      id: profile.id,
      name: profile.name,
      address: profile.address,
      cross_street: profile.crossStreet,
      city: profile.city,
      state: profile.state,
      country: profile.country,
      postal_code: profile.postalCode,
      gmaps_link: profile.gmapsLink,
      phone_number: profile.phoneNumber,
      website: profile.website,
      menu_link: profile.menuLink,
      facebook_link: profile.facebookLink,
      instagram_link: profile.instagramLink,
      snapchat_link: profile.snapchatLink,
      twitter_link: profile.twitterLink,
      contact_email: profile.contactEmail,
      reservation_widget_link: profile.reservationWidgetLink,
      marketing_sender_name: profile.currentSender.marketingSenderName,
      marketing_email: profile.currentSender.marketingEmail,
      marketing_sender_role: profile.currentSender.marketingSenderRole,
      maximum_delivery_discount: profile.maximumDeliveryDiscount,
      custom_domain_prefix: profile.customDomainPrefix,
    },
    errHandler
  ).then(() => dispatch(postSuccessVenueProfile()))
}
